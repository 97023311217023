@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Noto+Sans+JP:300,400,700&subset=japanese");
@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,400i,600i&display=swap");
* {
  margin: 0;
  padding: 0;
  font-size: 100%;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: normal;
}

img {
  border: 0;
}

ul, ol {
  list-style-type: none;
  text-indent: 0;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

caption, th {
  font-style: normal;
  font-weight: normal;
  text-align: left;
}

em, strong {
  font-style: normal;
  font-weight: normal;
}

cite {
  font-style: normal;
}

input[type="text"] {
  -webkit-appearance: none;
  padding: 0;
  border: none;
  border-radius: 0;
  outline: none;
  background: none;
}

textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  resize: none;
  padding: 0;
  border: 0;
  outline: none;
  background: transparent;
}

input[type="submit"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 0;
  border: none;
  outline: none;
  background: transparent;
  border-radius: 0;
}

@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: normal;
}

@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Bold");
  font-weight: bold;
}

body {
  font-family: 'Noto Sans JP', "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, "游ゴシック", "Yu Gothic", "游ゴシック体", "YuGothic", Roboto, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

img {
  width: 100%;
  vertical-align: bottom;
}

* {
  box-sizing: border-box;
}

a {
  color: #3ac1bb;
}

/* PC */
@media screen and (min-width: 768px) {
  .forsp {
    display: none;
  }
  /*----------------------------------------
Style
-----------------------------------------*/
  body {
    background-color: #080808;
    color: #ffffff;
    font-size: 16px;
    line-height: 2;
    min-width: 1120px;
  }
  .inner {
    width: 1120px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 12px;
    padding-right: 12px;
  }
  .inner--full {
    width: 100%;
  }
  .profile {
    position: relative;
  }
  .profile .profile__01 {
    margin-top: -7.9375%;
  }
  .profile .profile__bg {
    position: relative;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    z-index: 1;
    background-color: #0e2996;
    height: 636px;
    transform: skewY(-8deg);
    box-shadow: 0 -80px 80px 20px #000000;
    background-image: linear-gradient(#112da6 0%, #010c3c 100%);
  }
  .profile .profile__content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    margin: 0 auto;
    z-index: 2;
  }
  .profile .profile__content .profile__content__inner {
    display: flex;
    align-items: flex-start;
  }
  .profile .profile__content .profile__content__inner .profile__content__text {
    width: 624px;
    text-align: center;
  }
  .profile .profile__content .profile__content__inner .profile__content__text h1 {
    margin-bottom: 32px;
  }
  .profile .profile__content .profile__content__inner .profile__content__text .profile__content__text__name {
    font-weight: bold;
    margin-bottom: 32px;
    line-height: 1;
  }
  .profile .profile__content .profile__content__inner .profile__content__text .profile__content__text__name__roll {
    font-size: 20px;
    margin-bottom: 20px;
  }
  .profile .profile__content .profile__content__inner .profile__content__text .profile__content__text__name__name {
    font-size: 48px;
    margin-bottom: 20px;
  }
  .profile .profile__content .profile__content__inner .profile__content__text .profile__content__text__name__en {
    font-weight: 200;
    font-size: 16px;
  }
  .profile .profile__content .profile__content__inner .profile__content__text__name__detail p:not(:first-child) {
    margin-top: 1.5em;
  }
  .profile .profile__content .profile__content__inner .profile__content__image {
    width: 520px;
    margin: -94px -28px 0;
    position: relative;
  }
  .profile .profile__content .profile__content__inner .profile__content__image .profile__content__image__history {
    position: absolute;
    bottom: -20px;
    right: -20px;
    background-color: rgba(255, 255, 255, 0.67);
    padding: 24px 34px;
    color: #092480;
    font-size: 14px;
    line-height: 1.71429;
  }
  h2.head {
    position: relative;
  }
  h2.head .head__ja {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 40px;
    font-weight: bold;
    width: 100%;
    text-align: center;
  }
  .about {
    margin-top: -120px;
    background-image: url("../img/bg_about.jpg");
    background-position: center;
    background-size: cover;
    padding-top: 360px;
    padding-bottom: 290px;
  }
  .about h2.head--about {
    width: 578px;
    margin-left: 45px;
  }
  .about .about__text {
    width: 750px;
    margin: 40px auto 0;
  }
  .about .about__text .about__text__image {
    margin-top: 40px;
  }
  .works {
    z-index: 1;
    position: relative;
    background-image: linear-gradient(#112da6 0%, #010c3c 100%);
    transform: skewY(-170deg);
    margin-top: -200px;
    padding-bottom: 250px;
  }
  .works h2.head--works {
    width: 594px;
    margin: 50px 0 120px auto;
  }
  .works .inner {
    transform: skewY(170deg);
  }
  .works ul {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .works ul li {
    width: 532px;
    margin-bottom: 60px;
  }
  .works ul li:nth-child(odd) {
    margin-top: -45px;
  }
  .works ul li:nth-child(even) {
    margin-top: 45px;
  }
  .target {
    position: relative;
    margin-top: -200px;
    z-index: 2;
  }
  .target ul.target__list {
    display: flex;
    position: relative;
    z-index: 3;
  }
  .target ul.target__list li {
    background-position: center;
    background-size: cover;
    width: calc(100% / 3);
    padding: 8.75% 0 13.125%;
    text-align: center;
  }
  .target ul.target__list li.target__list__item--01 {
    background-image: url("../img/bg_target_01.jpg");
  }
  .target ul.target__list li.target__list__item--02 {
    background-image: url("../img/bg_target_02.jpg");
  }
  .target ul.target__list li.target__list__item--03 {
    background-image: url("../img/bg_target_03.jpg");
  }
  .target ul.target__list li .target__list__inner {
    width: 86.69202%;
    margin: 0 auto;
  }
  .target ul.target__list li h3 {
    width: 220px;
    height: 220px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    font-weight: bold;
    background-color: #0e2997;
    margin: 0 auto 60px;
  }
  .target ul.target__list li p {
    text-align: left;
    display: inline-block;
  }
  .target ul.target__list li .target__text__large {
    text-align: center;
    font-size: 34px;
    font-weight: bold;
    margin-top: 20px;
    line-height: 1.2;
  }
  .target .target__text {
    position: relative;
    z-index: 4;
  }
  .target .target__text:before {
    content: '';
    display: block;
    background-image: url("../img/bg_target_04.png");
    background-size: 100%;
    background-repeat: no-repeat;
    transform: translateY(-100%);
    height: 0;
    padding-top: 8.125%;
  }
  .target .target__text .inner {
    width: 670px;
    position: relative;
    margin-top: -50px;
    padding-bottom: 110px;
  }
  .target .target__text .inner h2 {
    font-size: 40px;
    text-align: center;
    font-weight: bold;
    margin-bottom: 30px;
  }
  .target .target__text .inner h2 img {
    width: 588px;
  }
  .target .target__text .inner .target__text__text {
    position: relative;
    z-index: 2;
  }
  .target .target__text .inner .target__text__image {
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: -340px;
    width: 470px;
  }
  .message .message__content {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
  }
  .message .message__content .message__content__text {
    width: 63.125%;
    background-color: #0e2997;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .message .message__content .message__content__text .message__content__text__inner {
    max-width: 740px;
    margin-left: auto;
    margin-right: auto;
    padding: 40px 30px;
  }
  .message .message__content .message__content__text h2 {
    font-size: 40px;
    font-weight: bold;
    margin-bottom: 30px;
    line-height: 1.2;
  }
  .message .message__content .message__content__image {
    width: 36.875%;
    background-color: #0e2997;
  }
  .flow {
    z-index: 1;
    padding: 100px 0 18.75%;
  }
  .flow ol li {
    background-color: #212121;
    padding: 0 0 50px 0;
  }
  .flow ol li:not(:first-child) {
    margin-top: 20px;
  }
  .flow ol li .flow__no {
    background-color: #0e2997;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    font-weight: bold;
  }
  .flow ol li h3 {
    margin-top: 30px;
    padding: 0 30px;
    font-size: 40px;
    font-weight: bold;
  }
  .flow ol li p {
    margin-top: 5px;
    padding: 0 30px;
  }
  .services {
    z-index: 2;
    position: relative;
    background-image: linear-gradient(#112da6 0%, #010c3c 100%);
    transform: skewY(170deg);
    margin-top: -100px;
    padding-bottom: 220px;
  }
  .services h2.head--services {
    width: 858px;
    margin: 0 auto;
    transform: translateY(-20px);
  }
  .services .inner {
    transform: skewY(-170deg);
  }
  .services ul {
    display: flex;
    margin: 80px 10px 0;
  }
  .services ul li {
    margin: 10px;
    background-color: #ffffff;
    width: calc(100% / 3);
  }
  .services ul li h3 {
    background-color: #080808;
    font-size: 30px;
    line-height: 1.2;
    padding: 20px 40px;
    font-weight: bold;
  }
  .services ul li .service__list__text {
    padding: 20px 40px;
    color: #080808;
  }
  .voices {
    margin-top: -50px;
    position: relative;
    z-index: 3;
  }
  .voices h2.head--voices {
    width: 634px;
    margin: 0 auto;
  }
  .voices .voices__list {
    margin-top: 80px;
  }
  .voices .voices__list li {
    display: flex;
    justify-content: space-between;
  }
  .voices .voices__list li:not(:first-child) {
    margin-top: 80px;
  }
  .voices .voices__list li:nth-child(even) {
    flex-direction: row-reverse;
  }
  .voices .voices__list li:nth-child(even) .voices__list__text:before {
    left: auto;
    right: -40px;
    transform: scale(-1, 1);
  }
  .voices .voices__list li .voices__list__image {
    width: 31.59851%;
  }
  .voices .voices__list li .voices__list__text {
    width: 62.26766%;
    background-color: #ffffff;
    padding: 20px 50px 30px;
    box-shadow: 10px 10px 0px 0px #0e2997;
    position: relative;
  }
  .voices .voices__list li .voices__list__text:before {
    content: '';
    display: block;
    position: absolute;
    top: 110px;
    left: -40px;
    z-index: 2;
    width: 41px;
    height: 36px;
    background-image: url("../img/img_arrow.png");
    background-repeat: no-repeat;
    background-size: contain;
  }
  .voices .voices__list li .voices__list__text h3 {
    width: 385px;
    background-color: #0e2997;
    padding: 20px 0;
    font-size: 30px;
    font-weight: bold;
    text-align: center;
    line-height: 1;
    margin-left: -64px;
    margin-bottom: 30px;
  }
  .voices .voices__list li .voices__list__text h3 small {
    font-size: 20px;
  }
  .voices .voices__list li .voices__list__text p {
    color: #080808;
  }
  .contact {
    position: relative;
    margin-top: -18.75%;
    overflow: hidden;
    z-index: 4;
    padding-top: 6.875%;
    padding-bottom: 100px;
  }
  .contact .inner {
    position: relative;
    z-index: 3;
  }
  .contact:before {
    content: '';
    display: block;
    width: 100%;
    height: 200%;
    position: absolute;
    background-image: linear-gradient(#112da6 0%, #010c3c 100%);
    transform: skewY(170deg);
    transform-origin: top right;
  }
  .contact h2.head--contact {
    width: 796px;
    margin: 6.875% auto;
  }
  .contact .contact__content {
    width: 680px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
  }
  .contact .contact__content .contact__content__image {
    width: 256px;
  }
  .contact .contact__content .contact__content__text {
    width: 400px;
    text-align: center;
  }
  .contact .contact__content h3 {
    font-size: 30px;
    font-weight: bold;
    line-height: 1;
    padding: 10px;
    border-top: 1px solid #ffffff;
    border-bottom: 1px solid #ffffff;
    margin-bottom: 10px;
  }
  .contact .contact__content .contact__content__text__tel {
    font-size: 36px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  .contact .contact__content .contact__content__text__tel a {
    text-decoration: none;
    color: #ffffff;
  }
  .contact .contact__content .contact__content__text__tel:before {
    content: '';
    display: block;
    z-index: 2;
    width: 38px;
    height: 38px;
    margin-right: 10px;
    background-image: url("../img/ico_tel.png");
    background-repeat: no-repeat;
    background-size: contain;
  }
}

/* SP */
@media screen and (max-width: 767px) {
  .forpc {
    display: none;
  }
  /*----------------------------------------
Style
-----------------------------------------*/
  html {
    font-size: 1.33333vw;
  }
  body {
    background-color: #080808;
    color: #ffffff;
    font-size: 2.8rem;
    line-height: 2;
  }
  .inner {
    width: 72rem;
    margin-left: auto;
    margin-right: auto;
  }
  .inner--full {
    width: 100%;
  }
  .profile {
    position: relative;
  }
  .profile .profile__01 {
    margin-top: -7.9375%;
  }
  .profile .profile__bg {
    position: relative;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    z-index: 1;
    background-color: #0e2996;
    height: 208rem;
    transform: skewY(-8deg);
    box-shadow: 0 -px(80) 8rem 2rem #000000;
    background-image: linear-gradient(#112da6 0%, #010c3c 100%);
  }
  .profile .profile__content {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    margin: 0 auto;
    z-index: 2;
  }
  .profile .profile__content .profile__content__inner .profile__content__text {
    width: 68rem;
    margin: 0 auto;
    text-align: left;
  }
  .profile .profile__content .profile__content__inner .profile__content__text h1 {
    margin-bottom: 3.2rem;
    width: 60rem;
    margin-left: auto;
    margin-right: auto;
  }
  .profile .profile__content .profile__content__inner .profile__content__text .profile__content__text__name {
    font-weight: bold;
    margin-bottom: 3.2rem;
    line-height: 1;
    text-align: center;
  }
  .profile .profile__content .profile__content__inner .profile__content__text .profile__content__text__name__roll {
    font-size: 2rem;
    margin-bottom: 2rem;
  }
  .profile .profile__content .profile__content__inner .profile__content__text .profile__content__text__name__name {
    font-size: 4.8rem;
    margin-bottom: 2rem;
  }
  .profile .profile__content .profile__content__inner .profile__content__text .profile__content__text__name__en {
    font-weight: 200;
    font-size: 1.6rem;
  }
  .profile .profile__content .profile__content__inner .profile__content__text__name__detail p:not(:first-child) {
    margin-top: 1.5em;
  }
  .profile .profile__content .profile__content__inner .profile__content__image {
    margin: 0 auto;
    position: relative;
    transform: translateY(4rem);
  }
  .profile .profile__content .profile__content__inner .profile__content__image .profile__content__image__image {
    width: 52rem;
    border-radius: 26rem;
    margin: 0 auto;
  }
  .profile .profile__content .profile__content__inner .profile__content__image .profile__content__image__history {
    position: absolute;
    width: 90%;
    bottom: -2rem;
    right: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.67);
    padding: 2.4rem 3.4rem;
    color: #092480;
    font-size: 2.4rem;
    line-height: 1.71429;
    margin: 0 auto;
  }
  h2.head {
    position: relative;
  }
  h2.head .head__ja {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 4.4rem;
    font-weight: bold;
    width: 100%;
    text-align: center;
  }
  .about {
    margin-top: -12rem;
    background-image: url("../img/bg_about.jpg");
    background-position: center;
    background-size: cover;
    padding-top: 36rem;
    padding-bottom: 29rem;
  }
  .about h2.head--about {
    width: 46.2rem;
    margin-left: auto;
    margin-right: auto;
  }
  .about .about__text {
    margin: 4rem auto 0;
  }
  .about .about__text .about__text__image {
    margin-top: 4rem;
  }
  .works {
    z-index: 1;
    position: relative;
    background-image: linear-gradient(#112da6 0%, #010c3c 100%);
    transform: skewY(-170deg);
    margin-top: -20rem;
    padding-bottom: 25rem;
  }
  .works h2.head--works {
    width: 47.5rem;
    margin: 5rem auto 12rem;
  }
  .works .inner {
    transform: skewY(170deg);
  }
  .works ul li {
    width: 69rem;
    margin: 6rem 0 0 auto;
  }
  .target {
    position: relative;
    margin-top: -10rem;
    z-index: 2;
  }
  .target ul.target__list {
    z-index: 3;
  }
  .target ul.target__list li {
    background-position: center;
    background-size: cover;
    padding: 8.75% 0 8.75%;
    text-align: center;
  }
  .target ul.target__list li.target__list__item--01 {
    background-image: url("../img/bg_target_01.jpg");
  }
  .target ul.target__list li.target__list__item--02 {
    background-image: url("../img/bg_target_02.jpg");
  }
  .target ul.target__list li.target__list__item--03 {
    background-image: url("../img/bg_target_03.jpg");
  }
  .target ul.target__list li .target__list__inner {
    width: 86.69202%;
    margin: 0 auto;
  }
  .target ul.target__list li h3 {
    width: 22rem;
    height: 22rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 3rem;
    font-weight: bold;
    background-color: #0e2997;
    margin: 0 auto 3rem;
  }
  .target ul.target__list li p {
    text-align: left;
    display: inline-block;
  }
  .target ul.target__list li .target__text__large {
    text-align: center;
    font-size: 3.4rem;
    font-weight: bold;
    margin-top: 2rem;
    line-height: 1.2;
  }
  .target .target__text {
    position: relative;
    z-index: 4;
  }
  .target .target__text:before {
    content: '';
    display: block;
    background-image: url("../img/bg_target_04.png");
    background-size: 100%;
    background-repeat: no-repeat;
    transform: translateY(-100%);
    height: 0;
    padding-top: 8.125%;
  }
  .target .target__text .inner {
    width: 67rem;
    position: relative;
    margin-top: -px(50);
    padding-bottom: 54rem;
  }
  .target .target__text .inner h2 {
    font-size: 4.4rem;
    text-align: center;
    font-weight: bold;
    margin-bottom: 3rem;
  }
  .target .target__text .inner h2 img {
    width: 58.8rem;
  }
  .target .target__text .inner .target__text__text {
    position: relative;
    z-index: 2;
  }
  .target .target__text .inner .target__text__image {
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 5rem;
    width: 47rem;
  }
  .message .message__content {
    width: 100%;
    background-color: #0e2997;
    padding: 8rem 0;
  }
  .message .message__content .message__content__text {
    background-color: #0e2997;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .message .message__content .message__content__text .message__content__text__inner {
    max-width: 74rem;
    margin-left: auto;
    margin-right: auto;
    padding: 4rem 3rem;
  }
  .message .message__content .message__content__text h2 {
    font-size: 4.4rem;
    font-weight: bold;
    margin-bottom: 3rem;
    line-height: 1.2;
  }
  .message .message__content .message__content__image {
    width: 60rem;
    background-color: #0e2997;
    margin: 0 auto;
  }
  .flow {
    z-index: 1;
    padding: 10rem 0 18.75%;
  }
  .flow ol li {
    background-color: #212121;
    padding: 0 0 5rem 0;
  }
  .flow ol li:not(:first-child) {
    margin-top: 5rem;
  }
  .flow ol li .flow__no {
    background-color: #0e2997;
    width: 9rem;
    height: 9rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 3.2rem;
    font-weight: bold;
  }
  .flow ol li h3 {
    margin-top: 3rem;
    padding: 0 3rem;
    font-size: 4.4rem;
    font-weight: bold;
  }
  .flow ol li p {
    margin-top: 0.5rem;
    padding: 0 3rem;
  }
  .services {
    z-index: 2;
    position: relative;
    background-image: linear-gradient(#112da6 0%, #010c3c 100%);
    transform: skewY(170deg);
    margin-top: -px(100);
    padding-bottom: 22rem;
  }
  .services h2.head--services {
    width: 68.6rem;
    margin: 0 auto;
    transform: translateY(-px(20));
  }
  .services .inner {
    transform: skewY(-170deg);
  }
  .services ul {
    margin: 8rem 1rem 0;
  }
  .services ul li {
    margin: 5rem 1rem;
    background-color: #ffffff;
  }
  .services ul li h3 {
    background-color: #080808;
    font-size: 3.8rem;
    line-height: 1.2;
    padding: 4rem 4rem;
    font-weight: bold;
  }
  .services ul li .service__list__text {
    padding: 2rem 4rem;
    color: #080808;
  }
  .voices {
    margin-top: -px(50);
    position: relative;
    z-index: 3;
  }
  .voices h2.head--voices {
    width: 50.7rem;
    margin: 0 auto;
  }
  .voices .voices__list {
    margin-top: 8rem;
  }
  .voices .voices__list li:not(:first-child) {
    margin-top: 5rem;
  }
  .voices .voices__list li .voices__list__image {
    width: 50rem;
    margin: 0 auto 3rem;
  }
  .voices .voices__list li .voices__list__text {
    width: 70rem;
    margin: 0 auto;
    background-color: #ffffff;
    padding: 3rem 5rem 3rem;
    box-shadow: 1rem 1rem 0rem 0rem #0e2997;
    position: relative;
  }
  .voices .voices__list li .voices__list__text h3 {
    width: 38.5rem;
    background-color: #0e2997;
    padding: 3rem 0;
    font-size: 4rem;
    font-weight: bold;
    text-align: center;
    line-height: 1;
    margin-left: -px(64);
    margin-bottom: 3rem;
  }
  .voices .voices__list li .voices__list__text h3 small {
    font-size: 2rem;
  }
  .voices .voices__list li .voices__list__text p {
    color: #080808;
  }
  .contact {
    position: relative;
    margin-top: -18.75%;
    overflow: hidden;
    z-index: 4;
    padding-top: 6.875%;
    padding-bottom: 10rem;
  }
  .contact .inner {
    position: relative;
    z-index: 3;
  }
  .contact:before {
    content: '';
    display: block;
    width: 100%;
    height: 200%;
    position: absolute;
    background-image: linear-gradient(#112da6 0%, #010c3c 100%);
    transform: skewY(170deg);
    transform-origin: top right;
  }
  .contact h2.head--contact {
    width: 63.6rem;
    margin: 6.875% auto;
  }
  .contact .contact__content {
    width: 68rem;
    align-items: center;
    margin: 0 auto;
  }
  .contact .contact__content .contact__content__image {
    width: 25.6rem;
    margin: 0 auto;
  }
  .contact .contact__content .contact__content__text {
    text-align: center;
    margin: 0 auto;
  }
  .contact .contact__content h3 {
    font-size: 4rem;
    font-weight: bold;
    line-height: 1;
    padding: 1rem;
    border-top: 0.1rem solid #ffffff;
    border-bottom: 0.1rem solid #ffffff;
    margin-bottom: 1rem;
    margin-top: 5rem;
  }
  .contact .contact__content .contact__content__text__tel {
    font-size: 5.2rem;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  .contact .contact__content .contact__content__text__tel a {
    text-decoration: none;
    color: #ffffff;
  }
  .contact .contact__content .contact__content__text__tel:before {
    content: '';
    display: block;
    z-index: 2;
    width: 6rem;
    height: 6rem;
    margin-right: 1rem;
    background-image: url("../img/ico_tel.png");
    background-repeat: no-repeat;
    background-size: contain;
  }
}

/* anim */
.animElem.slideIn {
  transform: translateY(50px);
  opacity: 0;
}

.slideIn {
  transition: all 0.8s cubic-bezier(0.215, 0.61, 0.355, 1);
}
